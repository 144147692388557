import { Flex, Text, Button, Link, NextLinkFromReactRouter as RouterLink, OpenNewIcon, Image } from '@pancakeswap/uikit'
import styled from 'styled-components'
import CompositeImageTrade, { CompositeImageProps } from '../CompositeImageTrade'
import ColoredWordHeading from '../ColoredWordHeading'

const SwapImg = styled.img`
  display: block;
  width: 200px;
  ${({ theme }) => theme.mediaQueries.md} {
    width: 450px;
  }
`

interface SalesSectionButton {
  to: string
  text: string
  external: boolean
}

export interface SalesSectionProps {
  headingText: string
  bodyText: string
  reverse: boolean
  primaryButton: SalesSectionButton
  secondaryButton?: SalesSectionButton
  images: CompositeImageProps,
  category?: string
}

const SalesSection: React.FC<React.PropsWithChildren<SalesSectionProps>> = (props) => {
  const { headingText, bodyText, reverse, primaryButton, images, category } = props

  return (
    <Flex flexDirection="column" padding={['10px 20px', '10px 20px', '10px 20px', '60px 100px']}>
      <Flex
        flexDirection={['column-reverse', null, null, reverse ? 'row-reverse' : 'row']}
        alignItems={['flex-end', null, null, 'center']}
        justifyContent="space-between"
      >
        <Flex
          flexDirection="column"
          flex="1"
          ml={[null, null, null, reverse && '64px']}
          mr={[null, null, null, !reverse && '64px']}
          alignSelf={['flex-start', null, null, 'center']}
        >
          <ColoredWordHeading text={headingText} />
          <Text color="textSubtle" mb="24px">
            {bodyText}
          </Text>
          <Flex>
            <Button mr="16px" disabled>
              {primaryButton.external ? (
                <Link external href={primaryButton.to}>
                  <Text color="card" bold fontSize="16px">
                    {primaryButton.text}
                  </Text>
                </Link>
              ) : (
                <Text color="card" bold fontSize="16px">
                  {primaryButton.text}
                </Text>
                // <RouterLink to={primaryButton.to}>
                //   <Text color="card" bold fontSize="16px">
                //     {primaryButton.text}
                //   </Text>
                // </RouterLink>
              )}
            </Button>
          </Flex>
        </Flex>
        <Flex
          width={['200px', '200px', '200px', '400px']}
          flex={[null, null, null, '1']}
          margin='0 auto'
          mb={['24px', null, null, '0']}
          alignItems='center'
          justifyContent={category === 'swap' ? 'flex-start' : 'flex-end'}
        >
          {
            category === 'swap' ? (
              <SwapImg
                src='/images/ekey-home/sale-icon.svg'
              />
            ) : (
              <CompositeImageTrade {...images} />
            )
          }
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SalesSection
