import { Button, Flex, Heading, NextLinkFromReactRouter, Image, Box, Text } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import styled, { keyframes } from 'styled-components'

const flyingAnim = () => keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(0px, -8px);
  }
  to {
    transform: translate(0, 0px);
  }
`

const fading = () => keyframes`
  from {
    opacity: 0.9;
  }
  50% {
    opacity: 0.1;
  }
  to {
    opacity: 0.9;
  }
`

const BgWrapper = styled.div`
  z-index: -1;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0px;
`

const InnerWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% + 10px);
  bottom: -10px;
`

const BunnyWrapper = styled.div`
  width: 100%;
  animation: ${flyingAnim} 3.5s ease-in-out infinite;
  will-change: transform;
  > span {
    overflow: visible !important; // make sure the next-image pre-build blur image not be cropped
  }
`

const StarsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  & :nth-child(2) {
    animation: ${fading} 2s ease-in-out infinite;
    animation-delay: 1s;
  }

  & :nth-child(3) {
    animation: ${fading} 5s ease-in-out infinite;
    animation-delay: 0.66s;
  }

  & :nth-child(4) {
    animation: ${fading} 2.5s ease-in-out infinite;
    animation-delay: 0.33s;
  }
`

const BgImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`
const BannerIcon = styled.img`
  display: block;
  width: 100%;
  width: 115px;

  ${({ theme }) => theme.mediaQueries.md} {
    width: 214px;
  }
`
const Hero = () => {
  const { t } = useTranslation()

  return (
    <>
      <BgWrapper>
        <InnerWrapper>
          <BgImg src="/images/ekey-home/banner.png" />
        </InnerWrapper>
      </BgWrapper>
      <Flex
        position="relative"
        flexDirection={['column-reverse', null, null, 'row']}
        alignItems={['flex-end', null, null, 'center']}
        justifyContent="space-between"
        id="homepage-hero"
        padding={["0 24px", "0 24px", "0 24px", "0 0 0 80px"]}
      >
        <Box padding={['20px 10px', '20px 10px', '20px 10px', '140px 20px']} width={['100%', '100%', '100%', '60%']} margin={['0 auto', '0 auto%', '0 auto', 'unset']}>
          <Text color="white" mb="24px" fontSize={['36px', '36px', '36px', '52px']} fontWeight={700} >
            {t('The simplest, most reliable highest-yielding Web3 infrastructure.')}
          </Text>
          <Text color="rgba(255, 255, 255, 0.48)" mb="24px" fontSize='16px' fontWeight={700} width={['100%', '100%', '100%', '70%']}>
            {t('LSDFi, GambleFi, DEX, Derivatives, and Lending are five real-yield ecosystems that maximize returns for $EKEY holders.')}
          </Text>
        </Box>
        <Flex
          width={['115px', '115px', '115px', '200px']}
          margin={['0 auto', '0 auto', '0 auto', '0 calc(24.4% - 100px) 0 0']}
          position="relative"
          alignItems='center'
        >
          <BunnyWrapper>
            <BannerIcon src='/images/ekey/home-section1-icon.png'/>
          </BunnyWrapper>
        </Flex>
      </Flex>
    </>
  )
}

export default Hero
