import styled, { keyframes } from 'styled-components'
import { Box } from '@pancakeswap/uikit'

const floatingAnim = (x: string, y: string) => keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(${x}, ${y});
  }
  to {
    transform: translate(0, 0px);
  }
`

const Wrapper = styled(Box)<{ maxHeight: string }>`
  position: absolute;
  bottom: 20px;
  width: 150px;
  height: 150px;
  left: 0;
  bottom: 0;
  ${({ theme }) => theme.mediaQueries.md} {
    width: 300px;
    height: 300px;
    bottom: 34px;
  }

  & :nth-child(1) {
    animation: ${floatingAnim('0px', '15px')} 3s ease-in-out infinite;
    animation-delay: 0s;
    position: absolute;
    width: 85%;
    right: 0;
    bottom: 15px;
  }

  & :nth-child(2) {
    animation: ${floatingAnim('0px', '15px')} 3s ease-in-out infinite;
    animation-delay: 1.5s;
    position: absolute;
    width: 70%;
    left: -10%;
    top: 0;
  }
`

const DummyImg = styled.img<{ maxHeight: string }>`
  max-height: ${({ maxHeight }) => maxHeight};
  visibility: hidden;
`

const ImageWrapper = styled.img`
  display: block;
`
interface ImageAttributes {
  src: string
  alt: string
}

export interface CompositeImageProps {
  path: string
  attributes: ImageAttributes[]
}

interface ComponentProps extends CompositeImageProps {
  animate?: boolean
  maxHeight?: string
}

export const getImageUrl = (base: string, imageSrc: string, extension = '.png'): string =>
  `${base}${imageSrc}${extension}`

const CompositeImage: React.FC<React.PropsWithChildren<ComponentProps>> = ({
  path,
  attributes,
  maxHeight = '512px',
}) => {
  return (
    <Wrapper maxHeight={maxHeight}>
      {attributes.map((image) => (
        <ImageWrapper
          key={image.src}
          src={getImageUrl(path, image.src, '.png')}
          alt={image.alt}
        />
      ))}
    </Wrapper>
  )
}

export default CompositeImage
