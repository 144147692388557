import styled from 'styled-components'
import { Flex, Text, TicketFillIcon, PredictionsIcon, Box, Image } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import useTheme from 'hooks/useTheme'
import ColoredWordHeading from '../ColoredWordHeading'
import IconCard, { IconCardData } from '../IconCard'
import PredictionCardContent from './PredictionCardContent'
import LotteryCardContent from './LotteryCardContent'
import CompositeImage from '../CompositeImage'
import CompositeImagePrizeLeft from '../CompositeImagePrizeLeft'
import CompositeImagePrizeRight from '../CompositeImagePrizeRight'

const TransparentFrame = styled.div<{ isDark: boolean }>`
  padding: 60px 16px 80px;
  box-sizing: border-box;
  border-radius: 72px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 40px 100px 120px;
  }
`

const BgWrapper = styled.div`
  z-index: -1;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`

const BottomLeftImgWrapper = styled(Flex)`
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  max-width: 192px;

  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 100%;
  }
`

const TopRightImgWrapper = styled(Flex)`
  position: absolute;
  z-index: 2;
  right: 0;
  max-width: 192px;

  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 100%;
  }
`
const PredictionCardData: IconCardData = {
  icon: <PredictionsIcon width="36px" color="inverseContrast" />,
  background: 'linear-gradient(129.81deg, #E09400 27.27%, #FFDD86 100%)',
  borderColor: '#ffb237',
  // rotation: '-2.36deg',
}

const LotteryCardData: IconCardData = {
  icon: <TicketFillIcon color="white" width="36px" />,
  background: 'linear-gradient(129.81deg, #7357FF 27.27%, #C6BBFF 100%)',
  borderColor: '#3C1786',
  // rotation: '1.43deg',
}

const bottomLeftImage = {
  path: '/images/ekey-home/prize-left/',
  attributes: [
    { src: 'prizes-02', alt: 'prizes-02' },
    { src: 'prizes-01', alt: 'prizes-01' },
  ],
}

const topRightImage = {
  path: '/images/ekey-home/prize-right/',
  attributes: [
    { src: 'prizes-05', alt: 'prizes-05' },
    { src: 'prizes-03', alt: 'prizes-03' },
    { src: 'prizes-04', alt: 'prizes-04' },
  ],
}

const WinSection = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()

  return (
    <>
      <BgWrapper>
        <BottomLeftImgWrapper>
          <CompositeImagePrizeLeft {...bottomLeftImage} />
        </BottomLeftImgWrapper>
        <TopRightImgWrapper>
          <CompositeImagePrizeRight {...topRightImage} />
        </TopRightImgWrapper>
      </BgWrapper>
      <TransparentFrame isDark={theme.isDark}>
        <Flex flexDirection="column" alignItems="center" justifyContent="center">
          <ColoredWordHeading textAlign="center" text={t('Win millions in prizes')} />
          <Flex alignItems='center' justifyContent="center" mb="40px" flexWrap='wrap'>
            <Text color="textSubtle">{t('Provably fair, on-chain games.')}</Text>
            <Text color="textSubtle">
              {t('Win big with EtherKEY.')}
            </Text>
          </Flex>
          <Flex m="0 auto" flexDirection={['column', null, null, 'row']} width="100%">
            <Flex
              flex="1"
              width={['100%', '100%', '100%', '48%']}
              mr={[null, null, null, '24px']}
              mb={['32px', null, null, '0']}
            >
              <IconCard {...PredictionCardData}>
                <PredictionCardContent />
              </IconCard>
            </Flex>
            <Flex flex="1" width={['100%', '100%', '100%', '48%']}>
              <IconCard {...LotteryCardData}>
                <LotteryCardContent />
              </IconCard>
            </Flex>
          </Flex>
        </Flex>
      </TransparentFrame>
    </>
  )
}

export default WinSection
