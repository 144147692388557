import { TranslateFunction } from '@pancakeswap/localization'
import { SalesSectionProps } from '.'

export const tradeSectionData = (t: TranslateFunction): SalesSectionProps => ({
  category: 'trade',
  headingText: t('Trade anything. No registration, no hassle.'),
  bodyText: t('Trade any token on Arbitrum in seconds, just by connecting your wallet.'),
  reverse: false,
  primaryButton: {
    to: '/swap',
    text: t('Trade Now'),
    external: false,
  },
  secondaryButton: {
    to: 'https://docs.pancakeswap.finance/',
    text: t('Learn'),
    external: true,
  },
  images: {
    path: '/images/ekey-home/trade/',
    attributes: [
      { src: 'token-01', alt: t('token-01') },
      { src: 'token-02', alt: t('token-02') },
      { src: 'token-03', alt: t('token-03') },
      { src: 'token-04', alt: t('token-04') },
    ],
  },
})

// export const earnSectionData = (t: TranslateFunction): SalesSectionProps => ({
//   headingText: t('Earn passive income with crypto.'),
//   bodyText: t('PancakeSwap makes it easy to make your crypto work for you.'),
//   reverse: true,
//   primaryButton: {
//     to: '/farms',
//     text: t('Explore'),
//     external: false,
//   },
//   secondaryButton: {
//     to: 'https://docs.pancakeswap.finance/products/yield-farming',
//     text: t('Learn'),
//     external: true,
//   },
//   images: {
//     path: '/images/home/earn/',
//     attributes: [
//       { src: 'pie', alt: t('Pie chart') },
//       { src: 'stonks', alt: t('Stocks chart') },
//       { src: 'folder', alt: t('Folder with EKEY token') },
//     ],
//   },
// })

export const swapSectionData = (t: TranslateFunction): SalesSectionProps => ({
  category: 'swap',
  headingText: t('EKEY makes our world go round.'),
  bodyText: t(
    'EKEY token is at the heart of the PancakeSwap ecosystem. Buy it, win it, farm it, spend it, stake it... heck, you can even vote with it!',
  ),
  reverse: true,
  primaryButton: {
    to: '/swap?outputCurrency=0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82&chainId=56',
    text: t('Buy EKEY'),
    external: false,
  },
  secondaryButton: {
    to: 'https://docs.pancakeswap.finance/tokenomics/cake',
    text: t('Learn'),
    external: true,
  },

  images: {
    path: '/images/home/cake/',
    attributes: [
      { src: 'bottom-right', alt: t('Small 3d pancake') },
      { src: 'top-right', alt: t('Small 3d pancake') },
      { src: 'coin', alt: t('EKEY token') },
      { src: 'top-left', alt: t('Small 3d pancake') },
    ],
  },
})
