import styled from 'styled-components'
import { PageSection } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import Hero from './components/Hero'
import { tradeSectionData, swapSectionData } from './components/SalesSection/data'
import SalesSection from './components/SalesSection'
import WinSection from './components/WinSection'

const Home: React.FC<React.PropsWithChildren> = () => {

  const { t } = useTranslation()

  return (
    <>
      <PageSection
        innerProps={{ style: { margin: '0', width: '100%', padding: 0, maxWidth: 'unset'} }}
        index={2}
        hasCurvedDivider={false}
      >
        <Hero />
      </PageSection>
      <PageSection
        innerProps={{ style: { margin: '0', width: '100%', padding: 0, maxWidth: 'unset' } }}
        index={2}
        hasCurvedDivider={false}
        backgroundImage="url(/images/ekey-home/prizes-bg.png)"
        backgroundSize="100% 100%"
      >
        <WinSection />
      </PageSection>
      <PageSection
        innerProps={{ style: { margin: '0', width: '100%', padding: 0, maxWidth: 'unset' } }}
        index={2}
        hasCurvedDivider={false}
      >
        <SalesSection {...tradeSectionData(t)} />
      </PageSection>
      <PageSection
        innerProps={{ style: { margin: '0', width: '100%', padding: 0, maxWidth: 'unset' } }}
        index={2}
        hasCurvedDivider={false}
      >
        <SalesSection {...swapSectionData(t)} />
      </PageSection>
    </>
  )
}

export default Home
