import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    // <Svg viewBox="0 0 24 24" {...props}>
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M1.4144 13.4824L9.36944 5.52736L10.0765 6.23446C10.3694 6.52736 10.8443 6.52736 11.1372 6.23446C11.4301 5.94157 11.4301 5.4667 11.1372 5.1738L10.4301 4.4667L12.7281 2.16869C13.5092 1.38764 14.7755 1.38764 15.5565 2.16869L16.6173 3.22943C16.8125 3.42465 16.8125 3.74115 16.6173 3.93637C15.641 4.91268 15.641 6.49559 16.6173 7.4719C17.5936 8.44821 19.1765 8.44821 20.1528 7.4719C20.348 7.27669 20.6645 7.27669 20.8597 7.4719L21.9205 8.53265C22.7015 9.3137 22.7015 10.58 21.9205 11.3611L19.6224 13.6592L18.9153 12.9521C18.6224 12.6592 18.1475 12.6592 17.8546 12.9521C17.5617 13.245 17.5617 13.7198 17.8546 14.0127L18.5617 14.7198L10.6068 22.6748C9.82574 23.4558 8.55941 23.4558 7.77836 22.6748L6.7177 21.6141C6.52244 21.4189 6.52244 21.1023 6.7177 20.907C7.69401 19.9307 7.69401 18.3478 6.7177 17.3715C5.74139 16.3952 4.15848 16.3952 3.18217 17.3715C2.9869 17.5667 2.67032 17.5667 2.47506 17.3715L1.4144 16.3108C0.633351 15.5298 0.633351 14.2634 1.4144 13.4824ZM13.2584 7.29521C12.9655 7.00232 12.4907 7.00232 12.1978 7.29521C11.9049 7.5881 11.9049 8.06298 12.1978 8.35587L12.9049 9.06298C13.1978 9.35587 13.6727 9.35587 13.9655 9.06298C14.2584 8.77009 14.2584 8.29521 13.9655 8.00232L13.2584 7.29521ZM15.0262 10.1236C15.3191 9.83075 15.794 9.83074 16.0869 10.1236L16.794 10.8307C17.0869 11.1236 17.0869 11.5985 16.794 11.8914C16.5011 12.1843 16.0262 12.1843 15.7333 11.8914L15.0262 11.1843C14.7333 10.8914 14.7333 10.4165 15.0262 10.1236Z"
    //   />
    // </Svg>
    <svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_912_26162)">
      <path fillRule="evenodd" clipRule="evenodd" d="M-4.68439 18.4513C-4.94769 17.9658 -4.76757 17.3588 -4.28209 17.0955L25.4448 0.973168C25.9303 0.709868 26.5373 0.889982 26.8006 1.37546L28.7811 5.02717C27.669 6.25955 27.3948 8.10621 28.2313 9.6485C29.0678 11.1908 30.7651 11.9683 32.4046 11.7083L34.385 15.3599C34.6483 15.8453 34.4682 16.4524 33.9827 16.7157L4.25583 32.838C3.77035 33.1013 3.16334 32.9212 2.90004 32.4357L0.919424 28.7838C2.03121 27.5514 2.30517 25.705 1.46881 24.1629C0.632447 22.6208 -1.06457 21.8433 -2.7039 22.103L-4.68439 18.4513Z" fill="#F2802D"/>
      <path opacity="0.6" d="M16.4854 5.83301L25.0233 21.5755" stroke="#AA4800" strokeWidth="0.9" strokeDasharray="2.7 2.7"/>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 14.4106C0 13.8584 0.447715 13.4106 1 13.4106H39C39.5523 13.4106 40 13.8584 40 14.4106V20.2546C38.2608 20.869 37.0146 22.5276 37.0146 24.4772C37.0146 26.4268 38.2608 28.0853 40 28.6998V34.4106C40 34.9629 39.5523 35.4106 39 35.4106H0.999999C0.447714 35.4106 0 34.9629 0 34.4106V28.7653C1.84312 28.211 3.1858 26.5009 3.1858 24.4772C3.1858 22.4535 1.84312 20.7433 0 20.1891V14.4106Z" fill="#F2AA09"/>
      <path opacity="0.6" d="M27.5625 14.3325V34.2311" stroke="#5C3111" strokeDasharray="3 3"/>
      <defs>
      <clipPath id="clip0_912_26162">
      <rect width="34.838" height="16.7739" fill="white" transform="translate(0 0.589844)"/>
      </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;
