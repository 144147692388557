import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    // <Svg viewBox="0 0 24 24" {...props}>
    //   <path d="M3.18731 5.68438C2.44993 5.52604 2.44993 4.47393 3.18731 4.31559L5.3203 3.85755C5.58957 3.79973 5.79991 3.58939 5.85774 3.32012L6.31577 1.18713C6.47411 0.449748 7.52622 0.449751 7.68457 1.18713L8.1426 3.32012C8.20042 3.58939 8.41076 3.79973 8.68003 3.85755L10.813 4.31559C11.5504 4.47393 11.5504 5.52604 10.813 5.68438L8.68003 6.14241C8.41076 6.20024 8.20042 6.41058 8.1426 6.67985L7.68457 8.81284C7.52622 9.55022 6.47411 9.55022 6.31577 8.81284L5.85774 6.67985C5.79991 6.41058 5.58957 6.20024 5.3203 6.14241L3.18731 5.68438Z" />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M12 2.99998C15.866 2.99998 19 6.13399 19 9.99998C19 13.866 15.866 17 12 17C8.13401 17 5 13.866 5 9.99998C5 9.4477 4.55228 8.99998 4 8.99998C3.44772 8.99998 3 9.4477 3 9.99998C3 12.8894 4.36163 15.4608 6.47822 17.1075L5.58579 18C5.21071 18.3751 5 18.8838 5 19.4142V21.5C5 22.3284 5.67157 23 6.5 23H17.5C18.3284 23 19 22.3284 19 21.5V19.4142C19 18.8838 18.7893 18.3751 18.4142 18L17.5218 17.1075C19.6384 15.4608 21 12.8894 21 9.99998C21 5.02942 16.9706 0.999985 12 0.999985C11.4477 0.999985 11 1.4477 11 1.99998C11 2.55227 11.4477 2.99998 12 2.99998ZM12 19C10.6564 19 9.38156 18.7056 8.23649 18.1777L7 19.4142L7 21H17V19.4142L15.7635 18.1777C14.6184 18.7056 13.3436 19 12 19Z"
    //   />
    // </Svg>
    <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="29.8728" cy="19.0505" r="10.1267" fill="#F03D3D"/>
      <path d="M33.0369 22.0171L33.5983 20.2203L32.4041 19.867L33.9236 15.0035L32.2 14.4937L26.3051 18.3376L25.8223 19.8829L29.7374 21.0411L29.2882 22.4786L31.3935 23.1014L31.8426 21.6639L33.0369 22.0171ZM28.6736 18.7635L30.9201 17.2557L30.2988 19.2442L28.6736 18.7635Z" fill="#FFF9F9"/>
      <ellipse cx="14.1774" cy="15.0001" rx="14.1774" ry="14.1774" fill="#8B75FF"/>
      <path d="M19.6616 16.8487C19.4197 15.5488 18.4199 14.7461 17.1592 14.3478C17.9855 13.5809 18.4825 12.638 18.2655 11.472C17.8635 9.31202 15.6616 8.00118 12.7753 8.53833C9.83153 9.08616 8.26774 11.0978 8.66971 13.2578C8.88671 14.4238 9.68958 15.1248 10.7365 15.5431C9.68431 16.3717 9.04023 17.4805 9.28213 18.7803C9.71256 21.0932 12.0745 22.4138 15.2476 21.8233C18.3633 21.2435 20.0921 19.1616 19.6616 16.8487ZM12.0744 12.7428C11.9179 11.9018 12.338 11.2896 13.2555 11.1189C14.1348 10.9552 14.7469 11.3753 14.9035 12.2164C15.0422 12.9618 14.615 13.5358 13.7357 13.6994C12.8182 13.8702 12.2132 13.4883 12.0744 12.7428ZM12.6704 18.0706C12.5139 17.2296 13.0308 16.5005 14.216 16.2799C15.3438 16.0701 16.1266 16.5572 16.2831 17.3983C16.4574 18.3349 15.8951 19.0329 14.7673 19.2428C13.5822 19.4633 12.8447 19.0073 12.6704 18.0706Z" fill="#F9F8FF"/>
    </svg>
  );
};

export default Icon;
